export const LOGIN_REQUEST: 'LOGIN_REQUEST' = 'LOGIN_REQUEST';
export const LOGIN_REQUEST_SUCCESS: 'LOGIN_REQUEST_SUCCESS' = 'LOGIN_REQUEST_SUCCESS';
export const LOGIN_REQUEST_FAILED: 'LOGIN_REQUEST_FAILED' = 'LOGIN_REQUEST_FAILED';

export const LOGOUT_REQUEST: 'LOGOUT_REQUEST' = 'LOGOUT_REQUEST';
export const LOGOUT_REQUEST_SUCCESS: 'LOGOUT_REQUEST_SUCCESS' = 'LOGOUT_REQUEST_SUCCESS';
export const LOGOUT_REQUEST_FAILED: 'LOGOUT_REQUEST_FAILED' = 'LOGOUT_REQUEST_FAILED';

export const USER_REQUEST: 'USER_REQUEST' = 'USER_REQUEST';
export const USER_REQUEST_SUCCESS: 'USER_REQUEST_SUCCESS' = 'USER_REQUEST_SUCCESS';
export const USER_REQUEST_FAILED: 'USER_REQUEST_FAILED' = 'USER_REQUEST_FAILED';

export const MODAL_TRUE: 'MODAL_TRUE' = 'MODAL_TRUE';
export const MODAL_FALSE: 'MODAL_FALSE' = 'MODAL_FALSE';

export const ADD_INGREDIENTS: 'ADD_INGREDIENTS' = 'ADD_INGREDIENTS';
export const REMOVE_INGREDIENTS: 'REMOVE_INGREDIENTS' = 'REMOVE_INGREDIENTS';
export const UPDATE_INGREDIENTS: 'UPDATE_INGREDIENTS' = 'UPDATE_INGREDIENTS';
export const CLEAR_INGREDIENTS: 'CLEAR_INGREDIENTS' = 'CLEAR_INGREDIENTS';

export const INGREDIENTS_REQUEST: 'INGREDIENTS_REQUEST' = 'INGREDIENTS_REQUEST';
export const INGREDIENTS_REQUEST_SUCCESS: 'INGREDIENTS_REQUEST_SUCCESS' = 'INGREDIENTS_REQUEST_SUCCESS';
export const INGREDIENTS_REQUEST_FAILED: 'INGREDIENTS_REQUEST_FAILED' = 'INGREDIENTS_REQUEST_FAILED';

export const REQUEST_FORGOT_PASSWORD_API: 'REQUEST_FORGOT_PASSWORD_API' = 'REQUEST_FORGOT_PASSWORD_API';
export const REQUEST_FORGOT_PASSWORD_API_SUCCESS: 'REQUEST_FORGOT_PASSWORD_API_SUCCESS' =
    'REQUEST_FORGOT_PASSWORD_API_SUCCESS';
export const REQUEST_FORGOT_PASSWORD_API_FAILED: 'REQUEST_FORGOT_PASSWORD_API_FAILED' =
    'REQUEST_FORGOT_PASSWORD_API_FAILED';

export const GET_ORDER: 'GET_ORDER' = 'GET_ORDER';
export const GET_ORDER_SUCCESS: 'GET_ORDER_SUCCESS' = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAILED: 'GET_ORDER_FAILED' = 'GET_ORDER_FAILED';

export const WS_CONNECTION_START: 'WS_CONNECTION_START' = 'WS_CONNECTION_START';
export const WS_CONNECTION_SUCCESS: 'WS_CONNECTION_SUCCESS' = 'WS_CONNECTION_SUCCESS';
export const WS_CONNECTION_ERROR: 'WS_CONNECTION_ERROR' = 'WS_CONNECTION_ERROR';
export const WS_CONNECTION_CLOSED: 'WS_CONNECTION_CLOSED' = 'WS_CONNECTION_CLOSED';
export const WS_GET_MESSAGE: 'WS_GET_MESSAGE' = 'WS_GET_MESSAGE';
export const WS_SEND_MESSAGE: 'WS_SEND_MESSAGE' = 'WS_SEND_MESSAGE';

export const GET_ORDER_FEED: 'GET_ORDER_FEED' = 'GET_ORDER_FEED';
export const GET_ORDER_SUCCESS_FEED: 'GET_ORDER_SUCCESS_FEED' = 'GET_ORDER_SUCCESS_FEED';
export const GET_ORDER_FAILED_FEED: 'GET_ORDER_FAILED_FEED' = 'GET_ORDER_FAILED_FEED';

export const GET_ORDER_FEED_USER: 'GET_ORDER_FEED_USER' = 'GET_ORDER_FEED_USER';
export const GET_ORDER_SUCCESS_FEED_USER: 'GET_ORDER_SUCCESS_FEED_USER' = 'GET_ORDER_SUCCESS_FEED_USER';
export const GET_ORDER_FAILED_FEED_USER: 'GET_ORDER_FAILED_FEED_USER' = 'GET_ORDER_FAILED_FEED_USER';